export const initialAbility = Object.freeze([
  {
    action: 'read',
    subject: 'auth',
  },
]);

export const permissionsData = [
  { module: 'auth', read: false, write: false, create: false, delete: false },
  { module: 'user', read: false, write: false, create: false, delete: false },
  { module: 'student', read: false, write: false, create: false, delete: false },
  { module: 'report', read: false, write: false, create: false, delete: false },
  { module: 'job', read: false, write: false, create: false, delete: false },
  { module: 'roster', read: false, write: false, create: false, delete: false },
  { module: 'placement', read: false, write: false, create: false, delete: false },
];

export const barePermissionsData = Object.freeze({
  auth: { read: false, write: false, create: false, delete: false },
  user: { read: false, write: false, create: false, delete: false },
  student: { read: false, write: false, create: false, delete: false },
  report: { read: false, write: false, create: false, delete: false },
  job: { read: false, write: false, create: false, delete: false },
  roster: { read: false, write: false, create: false, delete: false },
  placement: { read: false, write: false, create: false, delete: false },
});

export const _ = undefined;
